<template>
  <header class="header">
    <div class="header__wrapper d-flex align-items-center justify-content-center flex-column">
      <figure
        class="header__hero"
        title="Menu de Procedimentos - Trois Beauté"
      >
        <picture>
          <source media="(max-width: 767px)" :srcset="require('@/assets/images/menu-procedimentos-hero-mobile.webp')">
          <source media="(min-width: 768px)" :srcset="require('@/assets/images/menu-procedimentos-hero.webp')">
          <!-- Fallback image for browsers that do not support the picture element -->
          <img loading="lazy" alt="Trois Beauté" :src="require('@/assets/images/menu-procedimentos-hero-mobile.webp')">
        </picture>

      </figure>

    </div>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
}
</script>

<style lang="scss" scoped>
  .header {
    &__wrapper {
      margin: 0 auto;
      padding: 0;
      max-width: 1366px;
    }

    &__hero {
      &,
      img {
        width: 100%;
      }
    }

    &__pagetitle {
      margin: 20px 0 0;

      color: #f8f8f8;
      font: 500 1.5625rem "Montserrat", sans-serif;
    }
  }

</style>