<template>
  <article :class="additionalClasses" class="service cursor-pointer">
    <div class="service__wrapper d-flex flex-wrap align-items-center">
      <div class="service__info d-flex flex-column flex-md-row">
        <div class="service__text d-flex flex-column">
          <div class="service__title-wrapper">
            <h3 class="service__title">
              {{ service.title }}
            </h3>
          </div>
          <div class="service__dropdown">
            <div class="service__dropdown-content" v-if="true" @click="onDropdownClick">
              <div class="service__controls d-flex flex-column h-100 justify-content-center">
                <label class="service__checkbox">
                  <input type="checkbox" :value="isSelected" class="d-flex w-100" :checked="isSelected" />
                </label>
              </div>
              <div class="service__dropdown-texts">
                <p class="service__description" v-if="true">
                  {{ service.description }}
                </p>
                <strong class="service__price d-block d-md-flex align-items-md-center justify-content-md-center">
                  {{ service.price }}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>


<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ServiceItem",
  data() {
    return {
      showDropdown: false,
    };
  },
  props: {
    service: {
      type: Object,
      required: true
    },
    additionalClasses2: {
      type: String,
      default: '',
    },
    isSelected2: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState([
      'selectedServices'
    ]),
    isSelected () {
      return this.selectedServices.find(({ id }) => id === this.service.id);
    },
    additionalClasses () {
      return {
        'service--selected': this.isSelected
      }
    }
  },

  methods: {
    ...mapActions([
      'selectService',
      'removeService'
    ]),

    onDropdownClick() {
      const isSelectedService = this.selectedServices.find(({ id }) => id === this.service.id);

      if (!isSelectedService) {
        this.selectService(this.service);
      } else {
        this.removeService(this.service.id);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .service {
    &--selected {
      background-color: rgba(var(--pillColor), .1);
    }

    &__wrapper {
      flex-direction: row-reverse;
      touch-action: initial !important;
      background-color: #3A403F;
    }

    &__info {
      flex: 1;
    }

    &__controls {
      width: 15px;
    }

    &__checkbox {
      width: 15px;
      pointer-events: none;

      input {
        aspect-ratio: 1;
      }

      input[type="checkbox"]:checked {
        accent-color: #ffbe78;
        border: 2px solid #ffbe78 !important;
      }
    }

    &__pill {
      flex: 1 0 33px;
      margin: 12px auto 0;
      @include sizedBox(13px, 33px);

      border-radius: 13px;
      background-color: rgb(var(--pillColor));
    }

    &__text {
      margin-inline: 0 0;
      flex: 1 0 calc(100% - 27px);
    }

    &__title,
    &__price,
    &__description {
      --fs: .875rem;
    }

    &__title-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      padding: 0 15px;
      background-color: #343A39;
      //svg {
      //  margin-top: 22px;
      //  transition: transform 0.4s ease-in-out;
      //}
    }

    &__title {
      font: 600 var(--fs) "Montserrat", sans-serif;
      color: #f8f8f8;
      padding: 8px 0;
    }

    &__price {
      font: 400 var(--fs) "Montserrat", sans-serif;
      color: #fff;
      margin: 0;
    }

    &__description {
      margin-bottom: 8px;
      color: #dfdfdf;
      font: 400 var(--fs) "Montserrat", sans-serif;
    }

    @media (min-width: 768px) {
      &__wrapper {
        padding: 0;
      }
      &__title {
        --fs: 1rem;
      }

      &__title-wrapper {
        padding: 0 calc(93px + 15px);
      }

      &__dropdown-content {
        padding: 0 calc(93px + 15px) 8px !important;
      }

      &__text {
        flex: 1 0;
      }

      &__price {
        margin: 0;
        width: 180px;
        justify-content: flex-start;
      }
    }
  }

  .service__dropdown {
    position: relative;
    display: inline-block;
  }

  .service__dropdown-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    color: #888;
  }

  .service__dropdown {
    position: relative;
    display: inline-block;
  }

  .service__dropdown-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    color: #888;
  }

  .service__dropdown-content {
    display: flex;
    position: relative;
    align-items: center;
    gap: 15px;
    z-index: 0;
    left: 0;
    top: 100%;
    width: 100%;
    background-color: #343A39;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0 15px 15px;
    //border-top: solid 1px rgba(174, 177, 170, 0.2509803922);
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media (min-width: 768px) {
    .service {
      &__dropdown-content {
        position: initial;
      }
    }
  }
</style>