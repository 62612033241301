<template>
  <div class="wrapper wrapper--hasselection">
    <AppHeader />
<!--    <CategoryTabNav />-->
    <CategoryList />
    <a href="http://www.boldcomunicacao.com.br" target="_blank">
      <p class="agencia">Desenvolvido por <img src="https://www.botocenter.com.br/wp-content/uploads/2021/05/logolold.png" alt="Logo Bold Comunicação" srcset=""></p>
    </a>
    <StatusBar />
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';

import AppHeader from "@/components/AppHeader";
import CategoryList from "@/components/CategoryList";
import StatusBar from "@/components/StatusBar";
// import CategoryTabNav from "@/components/CategoryTabNav";

export default {
  name: 'App',

  components: {
    StatusBar,
    AppHeader,
    CategoryList,
    // CategoryTabNav,
  },

  data() {
    return {
      selected: 'tab1'
    }
  },
  computed: {
    ...mapState([
      'selectedServices'
    ]),
    ...mapGetters([
      'serviceList'
    ])
  },

  methods: {
    ...mapActions([
      'getServices'
    ]),
    setSelected(tab) {
      this.selected = tab;
    },
  },

  mounted () {
    this.getServices();
  }
}
</script>

<style lang="scss">
  .wrapper {
    transition: .4s ease-in-out;
    background-color: #343A39;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--hasselection {
      padding-bottom: 90px;
    }
  }

  .agencia {
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 8px;
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
   
    img {
      width: 32px;
      vertical-align: middle;
    }
  }
</style>
