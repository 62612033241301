<template>
  <main class="categorylist p-relative">
    <div class="categorylist__wrapper">
      <ul>
        <CategoryListItem
            v-for="category in filteredServiceList"
            :key="category.key"
            v-bind="{category}"
        />
      </ul>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex"
import CategoryListItem from "@/components/CategoryListItem";

export default {
  name: "CategoryList",

  components: {
    CategoryListItem
  },

  data() {
    return {
      showCategory: false,
      rotated: false,
    }
  },

  computed: {
    ...mapGetters({
      serviceList: 'serviceList',
      selectedService: 'getSelectedService',
      filteredServiceList: 'filteredServiceList'
    })
  },
}
</script>


<style lang="scss" scoped>
  .categorylist {
    &__wrapper {
      margin: 0 auto;
      padding: 0;
      max-width: 1366px;
    }

  }
</style>