
export default {
  services: [],

  selectedService: null,

  selectedServices: [],

  APIRoute: 'https://sheets.googleapis.com/v4/spreadsheets/1HXIbnPvyuBFvMLrFlh5yuaKcBLM-HKvmlsuUVy8dhpM/values/precos?alt=json&key=AIzaSyDRfCQaIA7G4DgwVzS8L5gBjTwlAELRxKE',

}
