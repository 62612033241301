
import { v4 as UUIDV4 } from "uuid";

import normalizeText from "@/utils/normalizer";

export default {
  getSelectedService (state) {
    return state.selectedService;
  },

  categoriesList (state) {
    return state.services.slice(1).reduce((acc, cur) => {
      const categoryName = cur[0].trim();

      if (acc.includes(categoryName)) return acc;

      return [...acc, categoryName];
    }, []);
  },

  serviceList (state) {
    return state.services.slice(1).reduce((acc, cur) => {
      const key = normalizeText(cur[0].toLowerCase().trim().replace(/[\s\t\n\r]+/g, '_'));

      if (acc[key]) {
        const [, title, price, description] = cur;

        acc[key].services.push({ id: UUIDV4(), title, price, description });

        return acc;
      }

      const [, title, price, description] = cur;

      return {
        ...acc,
        [key]: {
          key, name: cur[0].trim(), services: [{
            id: UUIDV4(), title, price, description
          }]
        }
      }
    }, {});
  },

  filteredServiceList (state, getters) {
    const { serviceList, getSelectedService } = getters;

    if (getSelectedService) {
      let selectedService = null;

      for (let service in serviceList) {
        if (serviceList[service].name === getSelectedService) {
          selectedService = serviceList[service];

          break;
        }
      }

      return {
        [selectedService.key]: selectedService
      };
    }

    return serviceList;
  }
}
