<template>
  <div style="display: contents">
    <li
        class="servicecategory"
        :class="{'servicecategory--visible' : showCategory }"
    >
      <div class="servicecategory__title" @click="toggleShowCategory">
        <h2 class="servicecategory__text">
          {{ category.name }}
        </h2>
        <svg class="servicecategory__arrow" width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 15.3122L8.33502 8.31038L2 1.30859" stroke="#FFBE78" stroke-width="2.11333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <section class="servicecategory__list">
        <ServiceItem
            :key="index"
            :service="service"
            v-for="(service, index) in category.services"
        />
      </section>
    </li>
  </div>
</template>

<script>
import ServiceItem from "@/components/ServiceItem";

export default {
  name: "CategoryList",

  components: {
    ServiceItem
  },

  props: {
    category: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showCategory: false,
    }
  },

  methods: {
    toggleShowCategory() {
      this.showCategory = !this.showCategory;
    }
  }
}
</script>


<style lang="scss" scoped>
.servicecategory {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2784313725);
  $this: &;

  max-height: 47px;
  overflow: hidden;

  &--visible{
    max-height: 3000px;
  }

  &, &__arrow {
    transition: all 500ms ease-in-out;
  }

  &__title {
    background-color: #3a403f;
    border-bottom: 1px solid #ffffff47;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;
  }

  &__text {
    color: #ffbe78;
    font: 600 0.813rem "Montserrat", sans-serif;
    text-transform: uppercase;
    padding: 15px 0;
  }

  &__arrow {
    #{$this}--visible & {
      transform: rotate(90deg);
    }
  }

  &__list {
    padding: 0;

    .service {
      border-top: solid 1px #aeb1aa73;
    }
    .service:last-child {
      border-bottom: solid 1px #AEB1AA40;
    }

  }


  @media(min-width: 768px) {
    &__title {
      padding: 0 calc(93px + 15px);
      background-color: #3a403f;
    }

    &__text {
      font-size: 1rem;
    }
  }
}
</style>